<template lang="pug">
.w-full.h-full.min-h-screen.flex.justify-center.items-center.px-2
  .default-card.w-full.max-w-lg.bg-gray-700.rounded-2xl.pb-8
    .font-bold.text-3xl.mb-1.border-b.border-gray-600.pb-2.mb-2.text-gray-200 Login
    baseInputText(v-model="email", labelText="Email", :iconPath="mdiMail")
    baseInputText.mt-2(
      typeInput="password",
      v-model="password",
      labelText="Password",
      :iconPath="mdiLock"
    )
    .default-button.inline-block.float-right.mt-4(@click="loginAdmin") Login
</template>
<script>
import { mdiMail, mdiLock } from "@mdi/js";
import authApi from "@/api/auth.repository";
import userApi from "@/api/users.repository";
export default {
  name: "Login",
  data: () => ({
    mdiMail,
    mdiLock,
    email: "",
    password: "",
  }),
  methods: {
    loginAdmin() {
      authApi
        .login(this.email, this.password)
        .then(({ data }) => {
          this.$store.dispatch("authStore/setToken", data.access_token);
          authApi
            .checkIsAdmin()
            .then(({ data }) => {
              userApi
                .getUserProfile(data.user._id)
                .then(({ data }) => {
                  if (data.user.role !== "admin") {
                    this.$store.commit("authStore/logout");
                  }
                })
                .catch(() => {
                  this.$store.commit("authStore/logout");
                });
            })
            .catch(() => {
              console.log("no es admin");
              this.$store.commit("authStore/logout");
            });
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>
<style lang="scss" scoped>
</style>